/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { getPropertyData } from './use-property';
import { CustomerInfo } from '../../build-info';
import { getConfigFrontEnd } from './use-config-fe';
import { getMainPropertyPicture } from '../ultility';

export interface ISEO {
    description?: string | '';
    lang?: string | 'en';
    meta?: { name?: string; content?: string; property?: string }[];
    title?: string;
}

function SEO({ description, lang, meta, title }: ISEO) {
    const property = getPropertyData();
    const websiteUrl = getConfigFrontEnd({ name: 'Website' });
    const propertyPicture = getMainPropertyPicture(property);

    return (
        <Helmet
            htmlAttributes={{
                lang: CustomerInfo.defaultLanguage
            }}
            title={property.TypeDescription || title}
            meta={[
                {
                    name: `author`,
                    content: `omnicasa`
                },
                {
                    name: `description`,
                    content: property.TypeDescription || description
                },
                {
                    property: `og:title`,
                    content: property.TypeDescription || title
                },
                {
                    property: `og:description`,
                    content: property.TypeDescription || description
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    property: `og:image`,
                    content: propertyPicture?.Url || ''
                },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    name: `twitter:creator`,
                    content: 'omnicasa'
                },
                {
                    name: `twitter:title`,
                    content: property.TypeDescription || title
                },
                {
                    name: `twitter:description`,
                    content: property.TypeDescription || description
                }
                // @ts-ignore
            ].concat(meta)}>
            <script type="application/ld+json">
                {`{
                    "@context": "http://schema.org/",
                    "@type": "Product", 
                    "name": "${property.TypeDescription || title}", 
                    "description":  "${property.TypeDescription || description}", 
                    "offers": 
                        { 
                            "@type": "Offer", 
                            "url": "${websiteUrl}", 
                            "itemCondition": "http://schema.org/NewCondition", 
                            "availability": "http://schema.org/InStock", 
                            "price": "0", 
                            "priceCurrency": "EUR", 
                            "priceValidUntil": "2021-11-20" }, 
                            "review": 
                                { 
                                    "@type": "Review", 
                                    "reviewRating": { "@type": "Rating", "ratingValue": "5", "bestRating": "5" }, 
                                    "author": {            
                                    "@type": "Person", 
                                    "name": "Bernard Vandaele"
                                } 
                        }
                    }`}
            </script>
        </Helmet>
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired
};

export default SEO;
