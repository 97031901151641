import React, { useState, useRef, useEffect } from 'react';
import Menu from '../menu';
import { useStaticQuery, graphql } from 'gatsby';
import { ImagesData } from '../../../components/gatsby-image';
import { FixedObject } from 'gatsby-image';
import classNames from 'classnames';
import { getConfigFrontEnd } from '../../../components/use-config-fe';
import { IAirtableAttachment } from 'src/model/airtable.model';

export interface IHeaderProps {
    children?: any;
}

interface IImageProps {
    name: string;
    style?: { [key: string]: string };
    className?: string;
    id?: string;
    alt?: string;
    isBanner?: boolean;
}

const LogoImage = (props: IImageProps) => {
    const data = useStaticQuery<ImagesData>(graphql`
        query {
            mobileImages: allFile {
                nodes {
                    id
                    name
                    childImageSharp {
                        fixed(width: 150) {
                            ...GatsbyImageSharpFixed_withWebp_noBase64
                        }
                    }
                }
            }
            
            desktopImages: allFile {
                nodes {
                    id
                    name
                    childImageSharp {
                        fixed(width: 350) {
                            ...GatsbyImageSharpFixed_withWebp_noBase64
                        }
                    }
                }
            }
        }
    `);

    const deskImg = data.desktopImages.nodes.find((img) => img.name === props.name || img.id === props.name);

    const mobileImg = data.mobileImages.nodes.find((img) => img.name === props.name || img.id === props.name);

    if (!deskImg?.childImageSharp || !mobileImg?.childImageSharp) {
        return <div></div>;
    }

    const desktopImage = Array.isArray(deskImg.childImageSharp.fixed) && deskImg.childImageSharp.fixed.length > 0
        ? deskImg.childImageSharp.fixed[0] as FixedObject
        : deskImg.childImageSharp.fixed as FixedObject;
    const mobileImage = Array.isArray(mobileImg.childImageSharp.fixed) && mobileImg.childImageSharp.fixed.length > 0
        ? mobileImg.childImageSharp.fixed[0] as FixedObject
        : mobileImg.childImageSharp.fixed as FixedObject;
    const deskClass = classNames({
        [!!props.className ? props.className : '']: true
    });

    return <picture>
        {desktopImage && (
            <>
                <source media='(min-width: 480px)' srcSet={desktopImage.srcSet} />
                <source
                    media='(min-width: 480px)'
                    srcSet={desktopImage.srcSetWebp}
                    type='image/webp'
                />
            </>
        )}
        <source srcSet={mobileImage.srcWebp} type='image/webp' />
        <img
            src={mobileImage.src}
            srcSet={mobileImage.srcSet}
            loading='eager'
            className={deskClass}
            alt={props.alt}
        />
    </picture>;
};

const Header = (props: IHeaderProps) => {
    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const headerRef = useRef<HTMLElement | null>(null);

    const configLogo2 = getConfigFrontEnd({ name: 'LogoImage2' }) as IAirtableAttachment;
    const configLogo = getConfigFrontEnd({ name: 'LogoImage' }) as IAirtableAttachment;

    console.log(configLogo2);

    // handle scroll event
    const handleScroll = (elTopOffset: number, elHeight: number) => {
        if (window.pageYOffset > elTopOffset + elHeight + 50) {
            setSticky({ isSticky: true, offset: elHeight });
        } else {
            setSticky({ isSticky: false, offset: 0 });
        }
    };

    // add/remove scroll event listener
    useEffect(() => {
        const handleScrollEvent = () => {
            handleScroll(header.top, header.height);
        };

        if (headerRef) {
            var header = headerRef.current!.getBoundingClientRect();
            window.addEventListener('scroll', handleScrollEvent);
        }

        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, [sticky]);

    return (
        <section className={`section section-header ${sticky.isSticky ? 'sticky' : ''}`} ref={headerRef}>
            <div className="container-fluid header">
                <div className="image-left">
                    <img src={configLogo2.url} />
                </div>
                <Menu />
                <div className="image-right">
                <img src={configLogo.url} />
                </div>
            </div>
        </section>
    );
};

export default Header;
