import { useStaticQuery, Node, graphql } from 'gatsby';
import { IProperty } from '../model/property.model';

type NodePropertyData = IProperty & Node;

export function getPropertyData() {
    const data = useStaticQuery<{
        property: NodePropertyData;
    }>(graphql`
        {
            property {
                ID
                Ident
                Residence
                Price
                GoogleX
                GoogleY
                VirtualTour
                VirtualTour2
                Street
                Zip
                HouseNumber
                City
                TypeDescription
                WebIDName
                PublishPrice
                PriceUnitText
                HeatingName
                HeatingBName
                HeatingComments
                HasGas
                HasWater
                HasLift
                KitchenName
                KitchenComments
                WindowGlazing
                WindowFrame
                InteriorName
                ExteriorName
                FloodzoneName
                FloodriskName
                FloodRiscAreaText
                RiverbankzoneName
                PreemptiveRightsText
                PermitText
                SummonsText
                AllotmentpermitText
                AreaApplicationName
                CertificateElectricityName
                CO2Emission
                EnergyPerformance
                EPCLabelText
                EPCCO2LabelText
                UniqueCode
                CertificateGasoline
                PublishAddress
                EnviromentName
                Floor
                NumberOfFloors
                NumberOfBedRooms
                NumberOfBathRooms
                NumberOfShowerRooms
                NumberOfToilets
                NumberOfGarages
                NumberOfParkings
                NumberOfExternalParkings
                SurfaceTotal
                SurfaceLiving
                SurfaceGround
                SurfaceGround2
                SurfaceConstructed
                DateFree
                FreeWhenSoldName
                ConstructionYear
                DescriptionA
                HtmlDescriptionE
                DescriptionE
                Site {
                    ID
                    Name
                    Phone
                    Email
                    City
                    Zip
                    HouseNumber
                    Street
                }
                DocumentItems {
                    ID
                    FileName
                    Comment
                    DocumentTypeID
                    DocumentTypeName
                }
                LargePictureItems {
                    Name
                    Url
                }
                SmallPictureItems {
                    Name
                    Url
                }
                XLargePictureItems {
                    Name
                    Url
                }
                LargePicture
                LargePictures
            }
        }
    `);

    return data.property;
}
