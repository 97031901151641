import React from 'react';
import { useStaticQuery, graphql, Node } from 'gatsby';
import { GatsbyImageFixedProps, FixedObject } from 'gatsby-image';
import classNames from 'classnames';

export interface IImageProps {
    name: string;
    style?: { [key: string]: string };
    className?: string;
    id?: string;
    alt?: string;
    isBanner?: boolean;
}

export interface ImagesData {
    mobileImages: {
        nodes: Array<{
            id: string;
            name: string;
            childImageSharp: GatsbyImageFixedProps;
        }> & Node;
    };
    desktopImages: {
        nodes: Array<{
            id: string;
            name: string;
            childImageSharp: GatsbyImageFixedProps;
        }> & Node;
    };
}

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const Image = (props: IImageProps) => {
    const data = useStaticQuery<ImagesData>(graphql`
        query {
            mobileImages: allFile {
                nodes {
                    id
                    name
                    childImageSharp {
                        fixed(width: 480) {
                            ...GatsbyImageSharpFixed_withWebp_noBase64
                        }
                    }
                }
            }
            
            desktopImages: allFile {
                nodes {
                    id
                    name
                    childImageSharp {
                        fixed(width: 1600) {
                            ...GatsbyImageSharpFixed_withWebp_noBase64
                        }
                    }
                }
            }
        }
    `);

    const deskImg = data.desktopImages.nodes.find((img) => img.name === props.name || img.id === props.name);

    const mobileImg = data.mobileImages.nodes.find((img) => img.name === props.name || img.id === props.name);

    if (!deskImg?.childImageSharp || !mobileImg?.childImageSharp) {
        return <div></div>;
    }

    const desktopImage = Array.isArray(deskImg.childImageSharp.fixed) && deskImg.childImageSharp.fixed.length > 0
        ? deskImg.childImageSharp.fixed[0] as FixedObject
        : deskImg.childImageSharp.fixed as FixedObject;
    const mobileImage = Array.isArray(mobileImg.childImageSharp.fixed) && mobileImg.childImageSharp.fixed.length > 0
        ? mobileImg.childImageSharp.fixed[0] as FixedObject
        : mobileImg.childImageSharp.fixed as FixedObject;
    const deskClass = classNames({
        [!!props.className ? props.className : '']: true
    });

    return <picture>
        {desktopImage && (
            <>
                <source media='(min-width: 480px)' srcSet={desktopImage.srcSet} />
                <source
                    media='(min-width: 480px)'
                    srcSet={desktopImage.srcSetWebp}
                    type='image/webp'
                />
            </>
        )}
        <source srcSet={mobileImage.srcWebp} type='image/webp' />
        <img
            src={mobileImage.src}
            srcSet={mobileImage.srcSet}
            loading='eager'
            className={deskClass}
            alt={props.alt}
        />
    </picture>;
};

export default Image;
