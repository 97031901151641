/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useLayoutEffect } from 'react';
import Header from '../header';
import Footer from '../footer';
import { getConfigFrontEnd } from '../../../components/use-config-fe';
import SEO from '../../../components/seo';
import '../../../../i18n';
import '../../../assets/font-icon/style.scss';
import '../../../assets/css/mini-default.css';
import '../../../assets/css/generate.scss';
import '../../../assets/css/layout.scss';
import '../../../assets/css/responsive.scss';
import '../../../assets/css/animation.css';
import { useDispatch } from 'react-redux';
import { changeDeviceActive, DeviceEnum } from '../../../state/device';

export interface ILayoutProps {
    children?: any;
}

const Layout = (props: ILayoutProps) => {
    const dispatch = useDispatch();
    const configButtonFontColor = getConfigFrontEnd({ name: 'ButtonFontColor' });
    const configButtonBackgroundColor = getConfigFrontEnd({
        name: 'ButtonBackgroundColor'
    });

    useLayoutEffect(() => {
        if (!!configButtonBackgroundColor) {
            document.documentElement.style.setProperty('--button-back-color', configButtonBackgroundColor);
        }
        if (!!configButtonFontColor) {
            document.documentElement.style.setProperty('--button-fore-color', configButtonFontColor);
        }

        function updateSize() {
            const innerWidth = !!window ? window.innerWidth : 0;
            if (innerWidth >= 1280) {
                dispatch(changeDeviceActive(DeviceEnum.DESKTOP));
            } else if (innerWidth >= 767 && innerWidth < 1280) {
                dispatch(changeDeviceActive(DeviceEnum.TABLET));
            } else if (innerWidth < 767) {
                dispatch(changeDeviceActive(DeviceEnum.MOBILE));
            }
        }
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, []);

    return (
        <>
            <SEO />
            <Header />
            {props.children}
            <Footer />
        </>
    );
};

export default Layout;
