import React from 'react';
import RenderConfigFe from '../../../components/render-config-fe';
import './styles.scss';

export default function Footer() {
    return (
        <>
            <section className="section section-copyright copyright">
                <div className="container-fluid text-center">
                    <RenderConfigFe name="FooterCopyright" type="html" />
                </div>
            </section>
        </>
    );
}
