import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IFrontEndConfig } from '../model/airtable.model';
import Image from './gatsby-image';

export interface IRenderConfigFEProps {
    name: string;
    type: 'string' | 'html' | 'image';
    className?: string;
    style?: { [key: string]: string };
}

export interface IFrontEndConfigData {
    configs: {
        nodes: IFrontEndConfig[];
    };
}

const RenderConfigFE = (props: IRenderConfigFEProps) => {
    const data = useStaticQuery<IFrontEndConfigData>(graphql`
        query {
            configs: allAirtableConfigFe {
                nodes {
                    Active
                    Name
                    Type
                    Value
                    Attachments {
                        id
                    }
                }
            }
        }
    `);
    const config = data.configs.nodes.find((c) => c.Name === props.name);

    if (!config || !!config && config.Type === 'image' && !config.Attachments) {
        return null;
    }

    return (
        <>
            {['html', 'string'].includes(config.Type) && (
                <div
                    className={props.className}
                    style={props.style}
                    dangerouslySetInnerHTML={{ __html: config.Value }}></div>
            )}
            {['image'].includes(config.Type) && config.Attachments[0].id && (
                <Image
                    className={props.className}
                    style={props.style}
                    name={config.Attachments[0].id}
                    alt={props.name}
                />
            )}
        </>
    );
};

export default RenderConfigFE;
