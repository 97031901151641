import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { menuSelector, changeMenuActive } from '../../../state/menu';
import { useSelector, useDispatch } from 'react-redux';
import './styles.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { IPage, IMenu } from '../../../model/airtable.model';
import { useTranslation } from 'react-i18next';

interface NodePageData extends IPage, Node {
    airtableId: string;
}

interface NodeMenuData extends IMenu, Node {
    airtableId: string;
}

export interface IMenuData {
    allMenu: {
        nodes: NodeMenuData[];
    };
    allPage: {
        nodes: NodePageData[];
    };
}

const Menu = () => {
    const queryResult = useStaticQuery<IMenuData>(graphql`
        query {
            allMenu: allAirtableMenu {
                nodes {
                    airtableId
                    Name
                    Page
                    Active
                }
            }
            allPage: allAirtablePage {
                nodes {
                    Name
                    id
                    airtableId
                    Type
                    Attachments {
                        id
                        filename
                    }
                    Menu
                }
            }
        }
    `);
    const { allMenu, allPage } = queryResult;

    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const { menuActive } = useSelector(menuSelector);

    const goTo = (scrollId: string) => {
        if (typeof window !== 'undefined') {
            dispatch(changeMenuActive(scrollId));
            const y = document.getElementById(scrollId)!.offsetTop - 50;
            window.scrollTo({
                top: y,
                left: 0,
                behavior: 'smooth'
            });
        }
    };

    const getScrollId = (menu: IMenu): string => {
        const page = allPage.nodes.find((page) => !!menu.Page && menu.Page.includes(page.airtableId));
        if (!!page && page.Name) return page.Name;
        return '';
    };

    useEffect(() => {
        if (location.hash) {
            dispatch(changeMenuActive(location.hash.replace('#', '')));
        }
        return () => { };
    }, []);

    const menuRender = allMenu.nodes;

    return (
        <div className="menu hidden-sm">
            {menuRender.map((menu, index) => {
                const scrollId = getScrollId(menu);
                return (
                    <span key={index}>
                        <a className={`${menuActive === scrollId ? 'active' : ''}`} onClick={() => goTo(scrollId)}>
                            {t(menu.Name).toUpperCase()}
                        </a>
                    </span>
                );
            })}
        </div>
    );
};

export default Menu;
