import lodash from 'lodash';
import { IPropertySite, IProperty, IPropertyPicture } from 'src/model/property.model';
const EPC_RANGE = {
    FLANDERS: [['-', 0, 'a_plus'], [0, 100, 'a'], [100, 200, 'b'], [200, 300, 'c'], [300, 400, 'd'], [400, 500, 'e'], [500, '+', 'f']],
    BRUSSELS: [['-', 0, 'a_plus'], [1, 23, 'a'], [24, 45, 'a_sub'], [46, 62, 'b_plus'], [63, 79, 'b'], [80, 95, 'b_sub'], [96, 113, 'c_plus'], [114, 132, 'c'], [133, 150, 'c_sub'], [151, 170, 'd_plus'], [171, 190, 'd'], [191, 210, 'd_sub'], [211, 232, 'e_plus'], [233, 253, 'e'], [254, 275, 'e_sub'], [276, 298, 'f_plus'], [299, 322, 'f'], [223, 345, 'f_sub'], [346, '+', 'g']],
    WALLONIA: [['-', 0, 'a_plusplus'], [0, 45, 'a_plus'], [45, 85, 'a'], [85, 170, 'b'], [170, 255, 'c'], [255, 340, 'd'], [340, 425, 'e'], [425, 510, 'f'], [510, '+', 'g']]
};

// Send a message to the parent
export function sendMessage(msg: { type: string; mess: string }) {
    // Make sure you are sending a string, and to stringify JSON
    window.parent.postMessage(msg, '*');
}

export function roundToFixed(num: any) {
    return Math.round(num * 100) / 100;
}

export function removeParamsNULL(params: any) {
    const newParams: any = { ...params };
    for (const propName in newParams) {
        if (
            newParams[propName] === null ||
            newParams[propName] === undefined ||
            newParams[propName] === '' ||
            newParams[propName] === '-1'
        ) {
            delete newParams[propName];
        }
    }
    return newParams;
}

export function stringValidJson(str: string): boolean {
    try {
        if (
            /^[\],:{}\s]*$/.test(
                str
                    .replace(/\\['\\\/bfnrtu]/g, '@')
                    .replace(/'[^'\\\n\r]*'|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
                    .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
            )
        ) {
            //the json is ok
            return true;
        } else {
            //the json is not ok
            return false;
        }
    } catch (ex) {
        console.error(ex);
        return false;
    }
}

export function PrepareTreeArray(arr: any, idField: string = 'ID', parentIdField: string = 'ParentID') {
    let tree = [];
    const mappedArr = [];
    let arrElem = [];
    let mappedElem = [];

    for (let i = 0, len = arr.length; i < len; i++) {
        arrElem = arr[i];
        mappedArr[arrElem[idField]] = arrElem;
        mappedArr[arrElem[idField]]['children'] = [];
    }

    for (const id in mappedArr) {
        if (mappedArr.hasOwnProperty(id)) {
            mappedElem = mappedArr[id];
            if (mappedElem[parentIdField]) {
                if (typeof mappedArr[mappedElem[parentIdField]] !== 'undefined') {
                    mappedArr[mappedElem[parentIdField]]['children'].push(mappedElem);
                }
            } else {
                tree.push(mappedElem);
            }
        }
    }

    tree = lodash.orderBy(tree, ['label'], ['asc']);
    tree.forEach((item: any) => {
        item.children = lodash.orderBy(item.children, ['label'], ['asc']);
    });

    return tree;
}

export function convertArrayStringToInt(arr: any[]) {
    return arr.map((a: any) => {
        if (typeof a === 'string') return parseInt(a, 10);
        return a;
    });
}

export function convertArrayIntToString(arr: any[]) {
    return arr.map((a) => {
        if (typeof a === 'number') return a.toString();
        return a;
    });
}

export function getLocalStorage(item: string) {
    if (!window) {
        return;
    }
    const data = window.localStorage.getItem(item) as string;

    if (stringValidJson(data)) {
        return JSON.parse(data);
    }

    return data;
}

export function setLocalStorage(item: string, data: any) {
    if (!window) {
        return;
    }
    window.localStorage.setItem(item, data);
    return data;
}

export function removeLocalStorage(item: string) {
    if (!window) {
        return;
    }
    window.localStorage.removeItem(item);
}

// addEventListener support for IE8
export function bindEvent(element: any, eventName: any, eventHandler: any) {
    if (element.addEventListener) {
        element.addEventListener(eventName, eventHandler, false);
    } else if (element.attachEvent) {
        element.attachEvent('on' + eventName, eventHandler);
    }
}

// removeEventListener support for IE8
export function removeEvent(element: any, eventName: string, eventHandler: any) {
    if (element.removeEventListener) {
        element.removeEventListener(eventName, eventHandler);
    } else if (element.attachEvent) {
        element.detachEvent('on' + eventName, eventHandler);
    }
}

/**
 * Delay after milisecond.
 *
 * @export
 * @param {number} timeout
 * @returns {Promise<any>}
 */
export function delay(timeout: number): Promise<any> {
    return new Promise<any>((resolve) => {
        setTimeout(resolve, timeout);
    });
}

/**
 * Retry with number & interval
 *
 * @export
 * @template T
 * @param {number} number
 * @param {(number | number[])} interval
 * @param {(Promise<T> | (() => Promise<T>))} action
 * @returns {(Promise<T | undefined>)}
 */
export async function retry<T>(
    number: number,
    interval: number | number[],
    action: () => Promise<T>,
    again?: (error: Error) => boolean
): Promise<T> {
    for (let tried = 0; tried < number; ++tried) {
        try {
            return await action();
        } catch (error) {
            if (!!again && !again(error)) {
                throw error;
            }
            if (tried === number - 1) {
                throw error;
            }
            if (interval instanceof Array) {
                if (tried < interval.length) {
                    await delay(interval[tried]);
                }
            } else {
                await delay(interval);
            }
        }
    }
    throw new RangeError('Invalied retry number.');
}

export const asyncLoading = (target: any, methodName: string, descriptor: TypedPropertyDescriptor<any>) => {
    const originalMethod = descriptor.value; // Save method

    descriptor.value = async function (...args: any[]): Promise<any> {
        Object.assign(this, { coLoading: true });

        const data = await originalMethod.apply(this, args);

        Object.assign(this, { coLoading: false });

        return data;
    };

    return descriptor;
};

export function getLanguage(code: 'en' | 'nl' | 'fr' | string) {
    switch (code) {
        case 'en':
            return 3;
        case 'fr':
            return 2;
        case 'en':
            return 1;
        default:
            return 1;
    }
}

export function getBoolean(value: string | boolean | number) {
    if (typeof value === 'string') value = value.trim();
    switch (value) {
        case 0:
        case '0':
        case 'false':
        case 'no':
        case false:
            return false;
        case 1:
        case '1':
        case 'true':
        case 'yes':
        case true:
            return true;
        default:
            return false;
    }
}

export function shorten(str: string, maxLen: number, separator = ' ') {
    if (str === '' || str === undefined || str === null) return '';
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen)) + '...';
}

export function formatPrice(number: any, unit: string) {
    switch (process.env.DEFAULT_LANGUAGE) {
        case 'nl':
            return unit + ' ' + number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        case 'fr':
            return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' ' + unit;
        default:
            return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' ' + unit;
    }
}

export function getCityAndCountry(city: string, countryID?: string | number, countryName?: string): string {
    let country = '';
    if (countryID && countryID !== 10) {
        if (countryName) {
            country = ` (${countryName})`;
        }
    }
    return city + country;
}

export function getAddressSite(prop?: IPropertySite) {
    let address: string = '';
    if (prop) {
        address += prop.Street + ' ' + prop.HouseNumber;

        if (address !== '') {
            address += ', ';
        }
        address += prop.Zip + ' ' + getCityAndCountry(prop.City);
    }
    return address;
}

export function getAddressProperty(prop?: IProperty) {
    let address: string = '';
    if (prop) {
        address += prop.Street + ' ' + prop.HouseNumber;

        if (address !== '') {
            address += ', ';
        }
        address += prop.Zip + ' ' + getCityAndCountry(prop.City!);
    }
    return address;
}

export function getMainPropertyPicture(property: IProperty): IPropertyPicture | null {
    let pictureOriginalUrl = null;

    if (
        pictureOriginalUrl === null &&
        Array.isArray(property.XLargePictureItems) &&
        property.XLargePictureItems.length > 0
    ) {
        pictureOriginalUrl = !!property.XLargePictureItems ? property.XLargePictureItems[0] : null;
    }

    if (
        pictureOriginalUrl === null &&
        Array.isArray(property.LargePictureItems) &&
        property.LargePictureItems.length > 0
    ) {
        pictureOriginalUrl = !!property.LargePictureItems ? property.LargePictureItems[0] : null;
    }

    if (
        pictureOriginalUrl === null &&
        Array.isArray(property.SmallPictureItems) &&
        property.SmallPictureItems.length > 0
    ) {
        pictureOriginalUrl = !!property.SmallPictureItems ? property.SmallPictureItems[0] : null;
    }

    return pictureOriginalUrl;
}

export function initGoogleRecaptcha() {
    console.log('GoogleRecaptchaModule mounted');
    const el = document.getElementById('GoogleRecaptchaModule');
    const elRecaptcha = document.getElementById('g-recaptcha');

    if (!!el) {
        el.remove();
    }

    if (!!elRecaptcha) {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js';
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.id = 'GoogleRecaptchaModule';
        document.body.appendChild(script);
    }
}

export function removeGoogleRecaptcha() {
    const el = document.getElementById('GoogleRecaptchaModule');
    if (!!el) {
        el.remove();
    }
}

export function getImageForEPC(prop: IProperty): { type: string, image: string } {
    if (prop === null || prop === undefined) { return { type: '', image: '' }; }
    let image = '';
    if (!!prop.EnergyPerformance) {
        const region = getRegionType(parseInt(prop.Zip!, 10));
        image = `/images/epc/${region.toLowerCase()}/${getEpcFromRangeDefault(parseFloat(prop.EnergyPerformance.toString()), region)}.png`;
        return { type: region, image: image };
    }
    return { type: '', image: image };
}

export function getRegionType(postCode: number) {
    if (postCode >= 1000 && postCode <= 1299) {
        return 'Brussels';
    } else if ((postCode >= 1300 && postCode <= 1499) || (postCode >= 4000 && postCode <= 7999)) {
        return 'Wallonia';
    } else if ((postCode >= 1500 && postCode <= 3999) || (postCode >= 8000 && postCode <= 9999)) {
        return 'Flanders';
    }
    return '';
}

export function getEpcFromRangeDefault(value: any, region: 'Flanders' | 'Brussels' | 'Wallonia' | '') {
    let range: any[] = [];
    switch (region) {
        case 'Flanders':
            range = EPC_RANGE.FLANDERS;
            break;
        case 'Brussels':
            range = EPC_RANGE.BRUSSELS;
            break;
        case 'Wallonia':
            range = EPC_RANGE.WALLONIA;
            break;
        case '': return '';
        default: return '';
    }
    let item = '';
    for (let i = 0; i < range.length; i++) {
        item = range[i];
        if (item[0] === '-' && item[1] >= value) {
            return item[2];
        } else if (item[0] <= value && item[1] === '+') {
            return item[2];
        } else if (item[0] <= value && item[1] >= value) {
            return item[2];
        }
    }
    return '';
}