import { useStaticQuery, graphql } from 'gatsby';
import { IFrontEndConfigData } from './render-config-fe';
import { stringValidJson, getBoolean } from '../ultility';

export function getConfigFrontEnd(props: { name: string }) {
    const data = useStaticQuery<IFrontEndConfigData>(graphql`
        query {
            configs: allAirtableConfigFe {
                nodes {
                    Active
                    Name
                    Type
                    Value
                    Attachments {
                        id
                        url
                    }
                }
            }
        }
    `);
    const config = data.configs.nodes.find((c) => c.Name === props.name);

    if (!config || !!config && config.Type === 'image' && !config.Attachments) {
        return null;
    }

    switch (config.Type) {
        case 'html':
        case 'string':
            return config.Value;
        case 'image':
            if (Array.isArray(config.Attachments) && config.Attachments.length > 0) return config.Attachments[0];
            else return '';
        case 'json':
            if (stringValidJson(config.Value)) return JSON.parse(config.Value);
            else return {};
        case 'boolean':
            return getBoolean(config.Value);
        default:
            return '';
    }
}
