const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

console.log(`Using environment config: '${activeEnv}'`);

require('dotenv').config({
    path: `${__dirname}/.env.${activeEnv}`
});

export const CustomerInfo = {
    defaultLanguage: process.env.DEFAULT_LANGUAGE || '',
    airtableApiKey: process.env.AIRTABLE_API_KEY || '',
    apiV2Token: process.env.APIV2 || '',
    captchaClientKey: process.env.CAPTCHA_CLIENT_KEY || '',
    base: process.env.BASE || '',
    customer: process.env.CUSTOMER || '',
    customerId: process.env.CUSTOMER_ID || '',
    objectId: process.env.OBJECT_ID || ''
};
